import AOS from "aos";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// Ready
document.addEventListener("DOMContentLoaded", function () {
	/*
	-------------------------------
	Animaciones scroll
	-------------------------------
	*/
	AOS.init();

	let scrollRef = 0;
	window.addEventListener("scroll", function () {
		// increase value up to 10, then refresh AOS
		if (scrollRef <= 10) {
			scrollRef++;
		} else {
			AOS.refresh();
		}
	});

	/*
	--------------------
	Validate default form
	--------------------
	*/
	var defaultForm = document.querySelector("#defaultForm");
	if (defaultForm) {
		var nombre = defaultForm.querySelector("#nombre");
		var email = defaultForm.querySelector("#email");
		var tel = defaultForm.querySelector("#tel");

		defaultForm.addEventListener("submit", function (e) {
			if (
				nombre.value === "" ||
				nombre.value === null ||
				nombre.value === false
			) {
				e.preventDefault();
				nombre.classList.add("is-invalid");
			} else {
				nombre.classList.remove("is-invalid");
			}

			if (
				email.value === "" ||
				email.value === null ||
				email.value === false
			) {
				e.preventDefault();
				email.classList.add("is-invalid");
			} else {
				email.classList.remove("is-invalid");
			}

			if (tel.value === "" || tel.value === null || tel.value === false) {
				e.preventDefault();
				tel.classList.add("is-invalid");
			} else {
				tel.classList.remove("is-invalid");
			}
		});
	}

	/*
	--------------------
	Proceso
	--------------------
	*/
	/*
	let procesoTimeline = gsap.timeline({ paused: true });

	procesoTimeline
		.from(".proceso ol li", {
			opacity: 0,
			stagger: 0.35,
		})
		.from(".proceso-wave", {
			opacity: 0,
		});

	ScrollTrigger.create({
		trigger: ".proceso",
		start: "top bottom-=200px",
		end: "bottom top",
		// markers: true,
		onEnter: () => {
			procesoTimeline.play();
		},
		onLeaveBack: () => {
			procesoTimeline.reverse();
		},
	});
	*/
});
